<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="name" placeholder="客户姓名" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="weChat" placeholder="微信" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="phone" placeholder="手机号" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="receiver" placeholder="接待人" size="medium"></el-input>
        </el-col>
        <el-col :span="4">
          <el-tooltip class="item" effect="dark" content="对接日期" placement="top">
            <el-date-picker
                style="width: 100%"
                v-model="createDateArray"
                type="daterange"
                size="medium"
                range-separator="至"
                start-placeholder="开始日期"
                value-format="yyyy-MM-dd"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px;">
        <el-col :span="3">
          <el-cascader
              :props="{ checkStrictly: true,
                        expandTrigger: 'hover',
                        multiple: true}"
              multiple="false"
              size="medium"
              :show-all-levels="false"
              style="width: 100%;"
              :options="sourceArray"
              v-model="source"
              placeholder="客资来源"
              ref="source"
              collapse-tags
              @change="cascaderClsoe(source)"
              clearable></el-cascader>
        </el-col>
        <el-col :span="3">
          <el-select v-model="grade" placeholder="客户意愿度" size="medium" clearable @change="gradeChange(grade)">
            <el-option
                v-for="item in gradeArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="projects" placeholder="预约项目" size="medium" clearable @change="projectsChange(projects)">
            <el-option
              v-for="item in projectsArray"
              :key="item.value"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="state" placeholder="客资状态" size="medium" clearable @change="stateChange(state)">
            <el-option
                v-for="item in stateArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
            </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="service" placeholder="客服" size="medium" clearable @change="serviceChange(service)">
            <el-option
                v-for="item in serviceArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="23" style="margin-top: 10px">
        <el-dropdown placement="bottom-start" style="width: 50%">
          <el-col :span="15">
            <el-input readonly="readonly" class="el-dropdown-link" placeholder="常用条件">
              <el-tag v-if='recordObject && recordObject.sourceName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.sourceName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.gradeName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.gradeName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.projectsName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.projectsName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.stateName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.stateName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.serviceName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.serviceName }}</el-tag>
            </el-input>
          </el-col>
          <el-col :span="8">
            <el-button el-dropdown-link type="success" icon="el-icon-star-off" size="medium " @click="collectionRecords()" circle></el-button>
            <el-button type="primary" @click="queryCusList" size="medium">搜索</el-button>
            <el-button type="warning" @click="reset" size="medium">重置</el-button>
          </el-col>
          <el-dropdown-menu  slot="dropdown" style="width: 35%;opacity: 0.95">
            <el-dropdown-item
                v-for="item in recordArray"
                :key="item.value"
                :label="item.showString"
                :value="item"
            >{{ item.preferenceSerialNumber }} : <el-tag type="warning" style="width: 90%" @click="useRecord(item)">{{item.showString}}</el-tag>
              <el-button type="danger" icon="el-icon-delete" size="medium" @click="deleteRecords(item)" circle></el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 15px;padding-left: 10px">
        <el-table
            :data="cusArray"
            border
            size="medium"
            v-loading="loading"
            element-loading-text="拼命加载中"
            max-height="640"
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
            :default-sort = "{prop: 'createDate', order: 'descending'}"
            style="width: 99%">
          <el-table-column prop="name" label="客户名" align="center" width="100" fixed show-overflow-tooltip></el-table-column>
          <el-table-column prop="createDate" label="对接日期" align="center" width="120" sortable fixed></el-table-column>
          <el-table-column prop="weChat" width="120" align="center" label="微信号" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="phone" width="125" align="center" label="手机号"></el-table-column>
          <el-table-column prop="city" width="100" align="center" label="所在城市"></el-table-column>
          <el-table-column prop="source" width="120" align="center" label="来源" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="state" width="120" align="center" label="状态"></el-table-column>
          <el-table-column prop="grade" width="120" align="center" label="意愿度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="service" width="120" align="center" label="客服"></el-table-column>
          <el-table-column prop="remark" width="120" align="center" label="备注" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="weddingDay" width="120" align="center" label="婚期"></el-table-column>
          <el-table-column prop="weddingVenue" width="120" align="center" label="婚期地点"></el-table-column>
          <el-table-column prop="entryPer" width="120" align="center" label="录入人"></el-table-column>
          <el-table-column width="410" align="center" fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <per-button type="primary" size="mini" @click="addAppoint(scope.row)" per="customer:appoint">预约</per-button>
                <per-button type="success" size="mini" @click="cusUpdate(scope.row)" per="customer:edit">编辑</per-button>
                <per-button type="warning" size="mini" @click="commAdd(scope.row)" per="customer:comm_add">沟通</per-button>
                <per-button type="success" size="mini" @click="directiveOrderAddOpen(scope.row)" per="customer:add_order">添加订单</per-button>
                <per-button type="primary" size="mini" @click="onlineOrderAdd(scope.row)" per="customer:online_order_add">线上订单</per-button>
                <per-button type="danger" size="mini" @click="cusDelete(scope.row)" per="customer:delete">删除</per-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <appAdd v-if="appAddState" :cus="cus" :appAddState="appAddState" @close="appAddClose"></appAdd>
    <cusUpdate v-if="cusUpdateState" :cus="cus" :cusUpdateState="cusUpdateState" @close="cusUpdateClose"></cusUpdate>
    <commAdd v-if="commAddState" :commAddState="commAddState" :cus="cus" @close="commAddClose"></commAdd>
    <onlineOrderAdd v-if="onlineOrderAddState" :onlineOrderAddState="onlineOrderAddState" :cus="cus" @close="onlineOrderAddClose"></onlineOrderAdd>
    <directive-order-add v-if="directiveOrderAdd" :state="directiveOrderAdd" :cus="cus" @close="directiveOrderAddClose"/>
    <recordSelect v-if="recordSelect" :recordObject="recordObject" :recordArray="recordArray" :recordState="recordSelect" @close="recordSelectAddClose"/>
  </div>
</template>

<script>
import appAdd from "@/pages/appoint/app-add"
import cusUpdate from "@/pages/customer/cus-update"
import commAdd from "@/pages/customer/comm/comm-add"
import onlineOrderAdd from "@/pages/order/online-order-add"
import directiveOrderAdd from "@/pages/order/direct-order-add"
import recordSelect from "@/components/record/record-select"

export default {
  name: "cus-list",
  created() {
    this.pageInit();
  },
  components: {
    appAdd,
    cusUpdate,
    commAdd,
    onlineOrderAdd,
    directiveOrderAdd,
    recordSelect
  },
  data() {
    return {
      emp: localStorage.getItem("emp"),
      name: "",
      phone: "",
      weChat: "",
      source: "",
      sourceResult: "",
      projects: "",
      dateSection: "",
      createDateArray: [],
      service: "",
      state: "",
      grade: "",
      receiver: "",
      testMessage: "",
      sourceArray: [],
      projectsArray: [],
      gradeArray: [],
      stateArray: [],
      serviceArray: [],
      recordArray: [],
      cusArray: [],
      total: 0,
      page: 1,
      limit: 100,
      appDialog: false,
      cus: {},
      appAddState: false,
      cusUpdateState: false,
      commAddState: false,
      onlineOrderAddState: false,
      loading: true,
      directiveOrderAdd: false,
      test: false,
      recordSelect: false,
      recordObject:{
        projectsName:"",
        projectsId: "",
        gradeName: "",
        gradeId: "",
        sourceName: "",
        sourceId: "",
        stateName: "",
        stateId: "",
        serviceName: "",
        serviceId: "",
        showString: "",
        empId: JSON.parse(localStorage.getItem("emp")).id,
        empName: JSON.parse(localStorage.getItem("emp")).empName,
        tenantCrop: localStorage.getItem("tenantCrop"),
        recordType: "客资列表",
        preferenceSerialNumber: "",
      }
    }
  },
  methods: {
    //页面初始化
     pageInit() {
      this.queryCusList();
      this.$selectUtils.querySourceIds(this.$selectUtils.cascader).then(response => {
        this.sourceArray = JSON.parse(JSON.parse(response.data.data))
      })
      this.$selectUtils.queryProjectsByType(this.$selectUtils.appointmentProjects).then(response => {
        this.projectsArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryGradeIds().then(response => {
        this.gradeArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryStateIds().then(response => {
        this.stateArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryRecord("客资列表").then(response => {
        this.recordArray = response.data.data
        this.recordObject.preferenceSerialNumber = this.recordArray.length + 1
      })
    },
    reset() {
      this.name = ""
      this.phone = ""
      this.weChat = ""
      this.source = ""
      this.createDate = ""
      this.service = ""
      this.state = ""
      this.grade = ""
      this.receiver = ""
      this.projects = ""
      this.createDateArray = []
      this.sourceResult = ""
      this.recordObject.sourceName = ""
      this.recordObject.gradeName = ""
      this.recordObject.projectsName = ""
      this.recordObject.stateName = ""
      this.recordObject.serviceName = ""
    },
    // 查询客资列表
    queryCusList() {
      if (this.createDateArray == null || this.createDateArray.length == 0) {
        this.dateSection = ""
      } else if (this.createDateArray.length > 0) {
        this.dateSection = this.createDateArray[0] +" - "+ this.createDateArray[1]
      }
      this.$axios({
        method: "get",
        url: "/customer/customerList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          limit: this.limit,
          page: this.page,
          name: this.name,
          phone: this.phone,
          weChat: this.weChat,
          source: this.sourceResult,
          dateSection: this.dateSection,
          service: this.service.value,
          state: this.state.value,
          grade: this.grade.value,
          projects: this.projects.value,
          receiver: this.receiver,
        }
      }).then(response => {
        console.log(response)
        this.loading = false
        this.cusArray = response.data.data.list;
        this.total = response.data.data.total;
      })
    },
    // 查询客资列表 通过收藏记录查询
    useRecord(val) {
      this.$axios({
        method: "get",
        url: "/customer/customerList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          limit: this.limit,
          page: this.page,
          source: val.sourceId,
          service: val.serviceId,
          state: val.stateId,
          grade: val.gradeId,
          projects: val.projectsId,
        }
      }).then(response => {
        console.log(response)
        this.loading = false
        this.cusArray = response.data.data.list;
        this.total = response.data.data.total;
      })
    },
    //将记录搜藏存储
    collectionRecords() {
      if (this.recordObject.preferenceSerialNumber > 5) {
        this.recordSelect = true
      } else {
        this.$axios({
          method: "post",
          url: "/record/addRecordOfCustomer",
          params: this.recordObject
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '收藏成功' : response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          console.log(this.sourceResult + "测试")
          this.pageInit()
          this.close()
        })
      }
    },
    deleteRecords(val){
      this.$axios({
        method: "post",
        url: "/record/deleteById",
        params: {
          id: val.id,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '删除成功' : response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
        this.reset()
        this.pageInit()
        this.close()
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryCusList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryCusList()
    },
    addAppoint(data) {
      this.appAddState = true
      this.cus = data
    },
    //添加预约列表关闭回调函数
    appAddClose() {
      this.appAddState = false
      this.queryCusList()
    },
    //级联选择关闭
    cascaderClsoe(val) {
      if (""!=val && null!=val) {
        if (""!=this.source) {
          let test = this.source[0]
          Array.from(test)
          if (test.length > 1) {
            this.sourceResult = test[1]
          } else {
            this.sourceResult = test[0]
          }
        }
        this.recordObject.sourceName = this.$refs["source"].getCheckedNodes()[0].label
        this.recordObject.sourceId = this.sourceResult
      } else {
        this.recordObject.sourceName = ""
        this.recordObject.sourceId = ""
        this.sourceResult = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
      console.log(val)
    },
    gradeChange(val) {
       if (""!=val.value && null!=val.value) {
         this.recordObject.gradeName = val.name
         this.recordObject.gradeId = val.value
       } else {
         this.recordObject.gradeName = ""
         this.recordObject.gradeId = ""
       }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    projectsChange(val) {
       if (""!=val.value && null!=val.value) {
         this.recordObject.projectsName = val.name
         this.recordObject.projectsId = val.value
       } else {
         this.recordObject.projectsName = ""
         this.recordObject.projectsId = ""
       }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    stateChange(val) {
       if (""!=val.value && null!=val.value) {
         this.recordObject.stateName = val.name
         this.recordObject.stateId = val.value
       } else {
         this.recordObject.stateName = ""
         this.recordObject.stateId = ""
       }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    serviceChange(val) {
       if (""!=val.value && null!=val.value) {
         this.recordObject.serviceName = val.name
         this.recordObject.serviceId = val.value
       } else {
         this.recordObject.serviceName = ""
         this.recordObject.serviceId = ""
       }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    showString() {
       return this.recordObject.sourceName + ";" + this.recordObject.gradeName + ";"
           + this.recordObject.projectsName + ";" + this.recordObject.stateName + ";"
           + this.recordObject.serviceName
    },
    //客资编辑页面
    cusUpdate(data) {
      this.cusUpdateState =true
      this.cus = data
    },
    //客资编辑界面销毁
    cusUpdateClose() {
      this.cusUpdateState = false
      this.queryCusList()
    },
    //添加沟通界面
    commAdd(data) {
      this.cus = data
      this.commAddState = true
    },
    //添加沟通界面销毁
    commAddClose() {
      this.commAddState = false
      this.queryCusList()
    },
    //线上订单添加界面
    onlineOrderAdd(data) {
      this.cus = data
      this.onlineOrderAddState = true
    },
    //线上订单添加界面销毁
    onlineOrderAddClose() {
       this.onlineOrderAddState = false
       this.queryCusList()
    },
    close() {
      this.$emit('close')
    },
    //客资删除
    cusDelete(data) {
      this.$alert("确认要删除 "+ data.name +" 客户吗？<br>"
                  + "<br><p style='color: red'>该客户下的预约、订单、收款、档期都将删除！！！</p>",
          "删除客资", {
        dangerouslyUseHTMLString: true,
        type: "warning",
        center: true,
        showCancelButton: true
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/customer/deleteCustomerById",
          params:{
            id: data.id
          }
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '删除客资成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          this.queryCusList()
        })
      }).catch(() => {})
    },
    directiveOrderAddClose() {
      this.directiveOrderAdd = false
    },
    recordSelectAddClose() {
      this.pageInit()
      this.recordSelect = false
    },
    directiveOrderAddOpen(val) {
      this.cus = val
      this.directiveOrderAdd = true
    },
  },
}
</script>

<style scoped>

</style>