<template>
  <div>
    <el-dialog
        title="添加沟通"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        center
        @close="close"
    >
    <el-form label-width="100px" :model="form" ref="form" :rules="rules">
      <el-row>
        <el-col :span="7">
          <el-form-item label="客户名">
            <el-input v-model="cus.name" placeholder="没有请填暂无" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="沟通方式" prop="commType">
            <el-select placeholder=请选择沟通方式 v-model="form.commType" style="width: 100%;" clearable>
              <el-option
                  v-for="item in commTypeArray"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="客户意愿度" prop="grade">
            <el-select v-model="form.commResult" placeholder="客户意愿度" clearable ref="grade">
              <el-option
                  v-for="item in gradeArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="设置提醒" v-if="check">
            <el-radio-group v-model="check">
              <el-radio-button :label="true">提醒</el-radio-button>
              <el-radio-button :label="false">不提醒</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="沟通周期" v-if="check" prop="commCycle">
            <el-select placeholder=请选择沟通周期 v-model="form.commCycle" style="width: 100%;" clearable>
              <el-option
                  v-for="item in commCycleArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="21">
          <el-form-item label="沟通内容" prop="commNote">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4}"
                placeholder="请输入内容"
                v-model="form.commNote">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="热门沟通内容">
            <div class="tag-group">
              <el-tag effect="dark" type="danger"
                    v-for="item in commNoteArray"
                      style="margin-left: 7px;cursor:pointer;"
                      @click="chooseCommNote(item.text)"
                    :key="item.text">
                {{item.text}}
              </el-tag>
            </div>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="加沟通图片" prop="commImage" :rules="{required:this.imageRuleFlag === '1', message:'请上传图片'}">
              <el-upload
                  list-type="picture-card"
                  action="#"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="handleAvatarChangeIcon">
                <img v-if="dialogImageUrl" :src="dialogImageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commAddSubmit">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "comm-add",
  created() {
    this.pageInit()
    this.imageControl()
  },
  props: {
    cus:{
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    commAddState: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return{
      file: {},
      imageFlag: "0",
      imageRuleFlag:true,
      dialogImageUrl: '',
      dialogVisible: false,
      state: this.commAddState,
      form:{
        //沟通方式
        commType: "",
        //意愿程度
        commResult: "",
        //沟通周期
        commCycle: "",
        //沟通内容
        commNote: "",
        //沟通图片
        commImage: "",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      commTypeArray: [{name: "微信沟通"},{name: "电话沟通"}],
      gradeArray: [],
      commCycleArray: [
          {name: "1天", value: "1天"}, {name: "2天", value: "2天"},
          {name: "3天", value: "3天"}, {name: "5天", value: "5天"},
          {name: "7天", value: "7天"}, {name: "14天", value: "14天"},
          {name: "30天", value: "30天"}, {name: "60天", value: "60天"},
      ],
      commNoteArray: [
        {text: "已约试纱"},{text: "微信跟进"},
        {text: "婚博会意向金客户"},{text: "只看免租"},
        {text: "已拉群"},{text: "取消预约，继续跟进"},
        {text: "已加上微信，打招呼未回复"},{text: "打招呼未回复"},
        {text: "发了款式未回复"},{text: "微信跟进已删客服微信"},
      ],
      rules: {
        commType: [{required: true, message: '请选择沟通方式', trigger: "change"}],
        commResult: [{required: true, message: '请选择意愿程度', trigger: "change"}],
        commCycle: [{required: true, message: '请选择沟通周期', trigger: "change"}],
        commNote: [{required: true, message: '请填写沟通内容', trigger: "blur"}],
      },
      check: true,
    }
  },
  methods: {
    imageControl(){
      this.$axios({
        method: "GET",
        url: "/viewControlTable/queryViewControlTableByName",
        params: {
          tenantCrop: this.form.tenantCrop,
          viewName: "沟通图片是否上传控制",
        }
      }).then(response => {
        this.imageFlag = response.data.data.isValid;
        if (this.imageFlag === 1) {
          this.imageRuleFlag = '1'
        } else {
          this.imageRuleFlag = '0'
        }
      })
    },
    handleAvatarChangeIcon(file) {
      this.file = file
      this.dialogImageUrl = URL.createObjectURL(file.raw)
      return new Promise((resolve, reject) => {
        if (this.dialogImageUrl) {
          this.$upload.commImageUpload(this.file.raw)
              .then(response => {
                let data = response.data
                if (data.code === 200) {
                  this.$message({
                    showClose: true,
                    message: '上传图片成功',
                    type: 'success',
                    duration: 1000,
                  });
                  this.form.commImage = data.data
                  if (this.form.commImage!=""){
                    this.imageRuleFlag = false
                  }
                  resolve(true)
                } else {
                  this.$message({
                    showClose: true,
                    message: '上传图片失败',
                    type: 'error',
                    duration: 1000,
                  });
                  reject(false)
                }
              })
        } else {
          resolve(true)
        }
      })
    },
    pageInit() {
      this.$selectUtils.queryGradeIds().then(response => {
        this.gradeArray = JSON.parse(response.data.data);
      })
    },
    close() {
      this.$emit('close')
    },
    chooseCommNote(val) {
      this.form.commNote = val
    },
    commAddSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert("沟通方式："+ this.form.commType
              + "<br>沟通内容："+ this.form.commNote
              + "<br>客户意愿程度："+ this.$refs.grade.selectedLabel
              + "<br>确认要添加吗？",
              "添加沟通",
              {
                dangerouslyUseHTMLString: true,
                type: "success",
                center: true,
                showCancelButton: true
          }).then(() => {
            this.form.cusId = this.cus.id
            this.$axios({
              method: "POST",
              url: "/comm/saveComm",
              params: this.form
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '沟通添加成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              this.file = {}
              this.fileName = ""
              this.dialogImageUrl = ""
              this.loading = false
              this.close();
            })
          }).catch(() => {});
        }
      })
    }
  },
}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  border: #8c939d;
}
</style>