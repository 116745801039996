import { render, staticRenderFns } from "./cus-list.vue?vue&type=template&id=e2fbf5e8&scoped=true&"
import script from "./cus-list.vue?vue&type=script&lang=js&"
export * from "./cus-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2fbf5e8",
  null
  
)

export default component.exports