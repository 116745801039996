<template>
  <div>
    <el-dialog
        title="添加订单"
        width="50%"
        top="10vh"
        :visible.sync="states"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-steps :active="active" simple>
        <el-step title="填写订单信息" icon="el-icon-edit"></el-step>
        <el-step title="填写收款信息" icon="el-icon-edit"></el-step>
        <el-step title="确认订单信息" icon="el-icon-check"></el-step>
        <el-step title="完成" icon="el-icon-finished"></el-step>
      </el-steps>
      <div style="margin-top: 3%">
        <el-form label-width="100px" :model="order" v-show="active===0" :rules="orderRules" ref="order">
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="订单号">
                <el-input v-model="order.orderNo" size="medium" placeholder="请填写订单号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="订单日期" prop="createDate">
                <el-date-picker type="date" placeholder="预约日期"
                                v-model="order.createDate"
                                style="width: 100%;"
                                size="medium"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="订单店铺" prop="shopId">
                <el-select v-model="order.shopId" placeholder="预约店铺" size="medium" clearable style="width: 100%">
                  <el-option
                      v-for="item in shopArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="订单项目" prop="orderName">
                <el-select v-model="order.orderName" placeholder="请选择订单项目" style="width: 100%"
                           filterable clearable ref="orderName" @change="orderNameChange">
                  <el-option
                      v-for="item in orderNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="精确婚期" prop="weddingDay">
                <el-date-picker type="date" placeholder="精确婚期"
                                v-model="order.weddingDay"
                                style="width: 100%;"
                                size="medium"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="订单备注">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 2}"
                    placeholder="请输入内容"
                    v-model="order.orderRemark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item style="margin-left: 30%">
            <el-button type="primary" @click="orderNext">下一步</el-button>
          </el-form-item>
        </el-form>
        <el-form label-width="80px" :model="process" :rules="processRules" v-show="active === 1" ref="process">
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="收款项目" prop="proceedsName">
                <el-select placeholder="请选择收款项目" size="medium" v-model="process.proceedsName" style="width: 100%;" @change="proceedsNameChange">
                  <el-option
                      v-for="item in proceedsNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="收款方式" prop="payment">
                <el-cascader
                    :props="{checkStrictly: true,
                            expandTrigger: 'hover',
                            label: 'name'}"
                    multiple="false"
                    :show-all-levels="false"
                    style="width: 100%;"
                    size="medium"
                    @change="cascaderClsoe"
                    placeholder="请选择收款方式"
                    :options="paymentArray"
                    v-model="process.payment"
                    ref="payment"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="订单总价">
                <el-switch
                    v-model="check"
                    active-color="#13ce66">
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="check">
            <el-col :span="12" :offset="6">
              <el-form-item label="订单总价">
                <el-input v-model="process.orderPrice" size="medium" placeholder="请输入订单总价"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="收款金额" prop="spareMoney">
                <el-input v-model.number="process.spareMoney" size="medium" placeholder="请输入收款金额"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="check">
            <el-col :span="12" :offset="6">
              <el-form-item label="余额">
                <el-input v-model="process.orderSpare" size="medium" placeholder="余额"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="6">
              <el-form-item label="收款人" prop="payee">
                <el-select placeholder="请选择收款人" size="medium" v-model="process.payee" style="width: 100%;" ref="payee" @change="payeeChange">
                  <el-option
                      v-for="item in payeeArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item style="margin-left: 30%">
            <el-button @click="previous">上一步</el-button>
            <el-button type="primary" @click="processNext">下一步</el-button>
          </el-form-item>
        </el-form>
        <div style="margin-left: 40%" v-show="active === 2">
          <el-descriptions :column="1" :labelStyle="{'font-size': '15px'}" :contentStyle="{'margin-left': '7%','font-size': '14px',}">
            <el-descriptions-item label="客户名">{{cus.name}}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{cus.phone}}</el-descriptions-item>
            <el-descriptions-item label="订单号">{{order.orderNo}}</el-descriptions-item>
            <el-descriptions-item label="订单项目" :contentStyle="{'margin-left': '4.4%'}">{{orderName}}</el-descriptions-item>
            <el-descriptions-item label="收款项目" :contentStyle="{'margin-left': '4.4%'}">{{proceedsName}}</el-descriptions-item>
            <el-descriptions-item v-if="check" label="订单总价" :contentStyle="{'margin-left': '4.4%'}">{{process.orderPrice}}</el-descriptions-item>
            <el-descriptions-item label="收款金额" :contentStyle="{'margin-left': '4.4%'}">{{process.spareMoney}}</el-descriptions-item>
            <el-descriptions-item label="收款金额" :contentStyle="{'margin-left': '4.4%'}">{{process.spareMoney}}</el-descriptions-item>
            <el-descriptions-item label="收款人" :contentStyle="{'margin-left': '7.7%'}">{{payee}}</el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 3%">
            <el-button @click="previous">上一步</el-button>
            <el-button type="primary" :loading="addLoading" @click="orderConfirm">确认添加</el-button>
          </div>
        </div>
        <div v-show="active === 3">
          <el-result icon="success" title="订单添加成功">
            <template slot="extra">
              <el-button type="primary" size="medium" @click="toOrderList">查看订单</el-button>
            </template>
          </el-result>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "direct-order-add",
  props: {
    cus: {
      type: Object,
      required: true,
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.pageInit();
  },
  watch: {
    'process.orderPrice'(valOne) {
      this.process.orderSpare = valOne - this.process.spareMoney;
    },
    'process.spareMoney'(valOne) {
      this.process.orderSpare = this.process.orderPrice - valOne;
    }
  },
  data() {
    return {
      states: this.state,
      active: 0,
      order:{
        // 订单日期
        createDate: new Date(),
        // 精确婚期
        weddingDay: "",
        // 订单号
        orderNo: "",
        // 订单项目
        orderName: "",
        //订单店铺
        shopId: localStorage.getItem("shops").split(",").map(Number)[0],
        // 订单备注
        orderRemark: "",
      },
      orderRules: {
        createDate: [{required: true, message: '请选择订单日期', trigger: 'change'}],
        shopId: [{required: true, message: '请选择订单店铺', trigger: 'change'}],
        orderName: [{required: true, message: '请选择订单项目', trigger: 'change'}],
        weddingDay: [{required: true, message: '请选择精确婚期', trigger: 'change'}],
      },
      shopArray: [],
      orderNameArray: [],
      process: {
        proceedsName: "",
        payment: "",
        orderPrice: "",
        spareMoney: "",
        orderSpare: "",
        payee: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
        createDate: new Date()
      },
      processRules: {
        proceedsName: [{required: true, message: '请选择收款项目', trigger: 'change'}],
        payment: [{required: true, message: '请选择收款方式', trigger: 'change'}],
        spareMoney: [{required: true, message: '请输入收款金额', trigger: 'blur'}],
        payee: [{required: true, message: '请选择收款人', trigger: 'change'}]
      },
      check: false,
      proceedsNameArray: [],
      paymentArray: [],
      payeeArray: [],
      addLoading: false,
      orderName: "",
      payee: "",
      proceedsName: "",
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    pageInit() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        let shops = localStorage.getItem("shops").split(",").map(Number)
        this.shopArray = JSON.parse(response.data.data).filter(s => shops.includes(s.value));
      })
      this.$selectUtils.queryProjectsIds(this.$projectsType.order).then(response => {
        this.orderNameArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryProjectsIds(this.$projectsType.proceeds).then(response => {
        this.proceedsNameArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryPaymentIds().then(response => {
        this.paymentArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryPayeeIds().then(response => {
        this.payeeArray = JSON.parse(response.data.data)
      })
    },
    orderNext() {
      this.$refs['order'].validate((valid) => {
          if (valid) {
            this.next()
          }
      })
    },
    next() {
      this.active++
    },
    previous() {
      this.active--
    },
    cascaderClsoe() {
      this.$refs.payment.dropDownVisible = false
    },
    processNext() {
      this.$refs['process'].validate((valid) => {
        if (valid) {
          this.next()
        }
      })
    },
    orderConfirm() {
      this.addLoading = true
      if ((typeof this.process.payment) === 'object') {
        this.process.payment = this.process.payment[this.process.payment.length -1]
      }
      this.$axios({
        method: "POST",
        url: "/order/addOrder",
        data: {
          ...this.order,
          ...this.process,
          cusId: this.cus.id
        }
      }).then((response) => {
        this.addLoading = false
        if (response.data.code === 200) {
          this.next()
        } else {
          this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error',
            duration: 1000,
          });
        }
      })
    },
    orderNameChange(val) {
      this.orderName = this.orderNameArray.filter(s => s.value === val)[0].name
    },
    payeeChange(val) {
      this.payee = this.payeeArray.filter(s => s.value === val)[0].name
    },
    proceedsNameChange(val) {
      this.proceedsName = this.proceedsNameArray.filter(s => s.value === val)[0].name
    },
    toOrderList() {
      this.$router.push("/work/order/order-list")
    },
  }
}
</script>

<style scoped>

</style>