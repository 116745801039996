<template>
  <div>
    <el-dialog
        title="编辑搜索偏好"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        center
        @close="close"
    >
      <el-select v-model="preferenceSerialNumber" placeholder="请选择偏好记录" size="medium" clearable @change="showStringChange(preferenceSerialNumber)">
        <el-option
          v-for="item in recordArray"
          :key="item.preferenceSerialNumber"
          :label="item.preferenceSerialNumber"
          :value="item.preferenceSerialNumber"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="collectionRecordsEdit()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "record-select",
  created() {
    this.recordObjectLocal = this.recordObject
  },
  props: {
    recordArray:{
      type: Array,
      required: true
    },
    recordState: {
      type: Boolean,
      required: true,
      default: false
    },
    recordObject:{
      type: Object,
      required: true
    }
  },

  data() {
    return {
      state: this.recordState,
      preferenceSerialNumber: "",
      recordObjectLocal: {},
    }
  },
  methods: {
    showStringChange(val) {
      this.recordObjectLocal.preferenceSerialNumber = val
      console.log(this.recordObject)
    },
    //将记录搜藏存储
    collectionRecordsEdit() {
      this.$axios({
        method: "post",
        url: "/record/addRecordOfCustomer",
        params: this.recordObjectLocal
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '收藏成功' : response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
        this.close()
      })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.el-select{
  width: 100%;
}
</style>